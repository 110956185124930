<template>
  <v-container fluid>
    <v-form
      ref="form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <h3>
                นโยบายคุ้กกี้
              </h3>
              <v-divider class="mt-3" />
            </v-col>
            <v-col cols="12">
              <editor
                v-model="formData.content"
                :disabled="loading" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Editor from '@/components/Editor.vue'
import CookiesConsentsProvider from '@/resources/cookies-consents.provider'

const CookiesConsentsService = new CookiesConsentsProvider()

export default {
  components: {
    Editor
  },
  data: () => ({
    loading: false,
    formData: {
      content: ''
    }
  }),
  mounted () {
    this.getItem()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'Contents' })
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await CookiesConsentsService.getLastItem()

        this.formData = data ? { ...data } : this.formData
      } catch (error) {
        console.error('getItem', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        if (!this.formData.content) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            type: 'error',
            timeout: 2000
          })

          return
        }

        await CookiesConsentsService.updateItem(this.formData)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไข ข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItem()
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
