import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class CookiesConsentsProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/cookies-consents')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/cookies-consents', payload)
  }
}

export default CookiesConsentsProvider
